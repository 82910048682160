<script setup lang="ts">
  import {useI18n} from 'vue-i18n';
  import {computed} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import CpModal from '~/components/cp/CpModal/CpModal.vue';
  import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';
  import CpButton from '~/components/cp/CpButton/CpButton.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import CpTextParser from '~/components/cp/CpTextParser/CpTextParser.vue';
  import CpLogo from '~/components/cp/CpLogo/CpLogo.vue';
  import CpPillPdp from '~/components/cp/CpPill/CpPillPdp/CpPillPdp.vue';
  import CpProductRate from '~/components/cp/CpProductRate/CpProductRate.vue';
  import {getReviewsData} from '~/utils';

  const props = defineProps<{
    show: boolean,
  }>();

  const emit = defineEmits<{
    (e: 'update:show', value: boolean): void,
    (e: 'download'): void,
  }>();

  const value = computed({
    get() {
      return props.show;
    },
    set(value) {
      emit('update:show', value);
    },
  });

  const {b, e} = useCpBem('cp-modal-app-download');
  const {t} = useI18n();
  const reviewsData = getReviewsData();
</script>

<template>
    <div :class="b">
        <CpModal
            v-model:show="value"
            @close-modal="value=false"
        >
            <CpSectionHeader
                type="h3"
                button="close"
                :title="t('downloadOurApp')"
                @header-action="value=false"
            />
            <div :class="e('body')">
                <div :class="e('sub-container')">
                    <div :class="e('logo')">
                        <CpLogo imago-type :primary="false" />
                    </div>
                    <div :class="e('info-container')">
                        <CpProductRate :rate="reviewsData.rate" hide-count />
                        <CpPillPdp
                            kind="reviews"
                            label-kind="primary"
                            :label="`${reviewsData.reviews} ${t('reviews')}`"
                            variant="white"
                            border="borderPrimary"
                        />
                    </div>
                </div>
                <div :class="e('sub-container')">
                    <CpText variant="body-bold">
                        {{ t('shoppingExperience') }}
                    </CpText>
                    <CpTextParser :class="e('text')" :text="t('downloadOurMobileApp')" variant="body" />
                </div>
            </div>
            <div :class="e('btn-container')">
                <CpButton
                    :label="t('downloadAndObtainBonus')"
                    kind="label"
                    full-width
                    @click="emit('download')"
                />
                <CpButton
                    :label="t('notNow')"
                    kind="label"
                    full-width
                    :hollow="true"
                    @click="value=false"
                />
            </div>
        </CpModal>
    </div>
</template>

<style scoped lang="scss">
  .cp-modal-app-download {
    &__body {
      display: flex;
      flex-direction: column;
      gap: $cp-gutter;
      align-items: center;
      padding: $cp-gutter;
      justify-content: center;
    }
    &__logo {
      padding: $cp-gutter-small;
      border-radius: $cp-border-radius;
      background-color: var(--color-primary);
      width: 60px !important;
      height: 60px !important;
      display: flex;
      align-items: center;
    }
    &__btn-container {
      border-top: $cp-border-standard;
      padding: $cp-gutter;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $cp-gutter;
    }
    &__info-container {
      display: flex;
      flex-direction: row;
      gap: $cp-gutter-xsmall;
    }
    &__text {
      text-align: center;
    }
    &__sub-container {
      display: flex;
      flex-direction: column;
      gap: $cp-gutter-small;
      align-items: center
    }
  }
</style>

<i18n locale="es-US">
  downloadOurApp: '¡Descarga nuestra app!'
  downloadAndObtainBonus: 'Descargar y obtener $100 de regalo'
  notNow: 'Por ahora no'
  shoppingExperience: '¡Tu experiencia de compra nunca fue tan fácil!'
  downloadOurMobileApp: '¡Descarga nuestra aplicación móvil y explora el mejor ecommerce de tecnología en México!
    Mantente al tanto de nuestras promociones y el estado de tus pedidos con nuestras notificaciones personalizadas.
    *¡Ingresa al mundo de la tecnología desde tu mano y recibe $100 de regalo al registrarte!*'
  reviews: 'opiniones'
</i18n>
